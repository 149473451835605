const PILOT = 'pilot';

const GUEST = 'guest';

const DELETED = 'deleted';

const ACTIVE = 'active';

const OTHER = `${PILOT},${GUEST},${DELETED}`;

const isOther = (status) => OTHER.split(',').includes(status);

export default {
  PILOT,
  GUEST,
  DELETED,
  ACTIVE,
  OTHER,
  isOther,
};
