<template>
  <div class="card company">
    <div class="company__logo">
      <ResponsiveImage class="company__logo__img" :image="logo" is4by3 />
    </div>
    <div class="company__info">
      <div class="company__info__name info__item">{{ company.name }}</div>
      <div class="company__info__signup_date info__item">
        <FontAwesomeIcon :icon="['fal', 'calendar-check']" />
        {{ getDate(company.createdAt) }}
      </div>
      <div v-if="company.email" class="company__info__mail info__item">
        <FontAwesomeIcon :icon="['fal', 'envelope']" />
        {{ company.email }}
      </div>
      <div v-if="company.phoneNumber" class="company__info__phone info__item">
        <FontAwesomeIcon :icon="['fal', 'phone-alt']" />
        {{ company.phoneNumber }}
      </div>
      <div
        v-if="company.pageViews !== null"
        class="company__info__views info__item"
        :title="`Cette entreprise a été consultée ${company.pageViews} fois`"
      >
        <FontAwesomeIcon :icon="['fal', 'eye']" />
        {{ company.pageViews }}
      </div>
    </div>
    <div class="company__status">
      <FontAwesomeIcon
        class="status_item"
        :class="company.showOnMap ? 'success' : 'error'"
        :icon="['fal', locationLogo]"
      />

      <FontAwesomeIcon
        class="status_item"
        :class="company.webShopUrl ? 'success' : 'error'"
        :icon="['fal', webShopLogo]"
      />

      <FontAwesomeIcon
        class="status_item"
        :class="company.hasDeliveryService ? 'success' : 'error'"
        :icon="['fal', deliveryIcon]"
      />

      <FontAwesomeIcon
        class="status_item"
        :class="company.hasTakeAwayService ? 'success' : 'error'"
        :icon="['fal', takeAwayIcon]"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faCalendarCheck,
  faEnvelope,
  faPhoneAlt,
  faStoreAlt,
  faStoreAltSlash,
  faMapMarkerCheck,
  faMapMarkerTimes,
  faDollyFlatbedEmpty,
  faDollyFlatbedAlt,
  faBoxCheck,
  faBox,
  faEye,
} from '@fortawesome/pro-light-svg-icons';

import ResponsiveImage from '../common/ResponsiveImage.vue';

library.add(
  faCalendarCheck,
  faEnvelope,
  faPhoneAlt,
  faStoreAlt,
  faStoreAltSlash,
  faMapMarkerCheck,
  faMapMarkerTimes,
  faDollyFlatbedEmpty,
  faDollyFlatbedAlt,
  faBoxCheck,
  faBox,
  faEye,
);

export default defineComponent({
  name: 'EnterpriseCard',
  components: {
    FontAwesomeIcon,
    ResponsiveImage,
  },
  props: {
    company: Object,
  },
  setup(props) {
    const { company } = toRefs(props);

    const locationLogo = computed(() => (company.value.showOnMap
      ? 'map-marker-check' : 'map-marker-times'));
    const webShopLogo = computed(() => (company.value.webShopUrl
      ? 'store-alt' : 'store-alt-slash'));
    const deliveryIcon = computed(() => (company.value.hasDeliveryService
      ? 'dolly-flatbed-alt' : 'dolly-flatbed-empty'));
    const takeAwayIcon = computed(() => (company.value.hasTakeAwayService
      ? 'box' : 'box-check'));
    // eslint-disable-next-line global-require
    const logo = computed(() => company.value?.mediaUrl || require('../../assets/images/default_picture.png'));

    const getDate = (stringDate) => {
      const date = new Date(stringDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      locationLogo,
      webShopLogo,
      deliveryIcon,
      takeAwayIcon,
      logo,
      getDate,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding: 2.5rem;
  border-radius: 1.5rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.company {
  display: flex;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }

  &__logo {
    align-self: center;
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    @include sm  {
      margin-right: 3rem;
    }
  }

  &__info {
    margin-right: auto;

    .info__item {
      [class*="fa-"] {
        margin-right: 0.75rem;
      }

      & + .info__item {
        margin-top: 0.5rem;
      }
    }

    &__name {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 1rem;

      @include sm {
        font-size: 3rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__status {
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sm {
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    .status_item {
      width: 2rem;
      height: 2rem;

      &.success {
        color: $success;
      }

      &.error {
        color: $error;
      }
    }
  }
}
</style>
