<template>
  <div
    class="image"
    :class="{ 'image--4by3-ratio': is4by3 }"
    :style="imageStyle"
  />
</template>

<script>
export default {
  name: 'ResponsiveImage',
  props: {
    image: String,
    is4by3: Boolean,
    borderRadius: String,
  },
  computed: {
    imageStyle() {
      const styles = [`background-image: url("${encodeURI(this.image)}")`];
      if (this.borderRadius) {
        styles.push(`border-radius: ${this.borderRadius}`);
      }

      return styles.join(';');
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  &--4by3-ratio {
    width: 100%;
    position: relative;
    background: url("../../assets/images/default_picture.png") 50% 50% no-repeat;
    background-size: cover;
    background-clip: content-box;

    &::before {
      display: block;
      content: "";
      padding-top: 75%;
    }
  }
}
</style>
