<template>
  <div class="length">
    {{ companies.length }}
    {{ ' ' }}
    {{ companies.length === 1 ? 'résultat' : 'résultats' }}
  </div>
  <div class="companies">
    <EnterpriseCard
      v-for="company in sortedCompanies"
      :key="company.id"
      :company="company"
      @click="goToEnterprise(company.id)"
    />
  </div>
</template>

<script>
import { computed, defineComponent, toRefs } from 'vue';
import { useRouter } from 'vue-router';

import EnterpriseCard from './EnterpriseCard.vue';
import useCurrentCompany from '../../composables/useCurrentCompany';

export default defineComponent({
  name: 'Enterprises',
  components: {
    EnterpriseCard,
  },
  props: {
    companies: Array,
    pageViews: Object,
  },
  setup(props) {
    const { companies, pageViews } = toRefs(props);

    const router = useRouter();
    const { setCurrentCompanyId } = useCurrentCompany();

    const companiesWithPageViews = computed(() => companies.value
      .map((c) => ({ ...c, pageViews: pageViews.value?.[c.id] || null })));
    const sortedCompanies = computed(() => {
      const copiedCompanies = [...companiesWithPageViews.value];

      return copiedCompanies
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    });

    const goToEnterprise = (id) => {
      setCurrentCompanyId(id);
      router.push('/profile');
    };

    return {
      sortedCompanies,
      goToEnterprise,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/mixins.scss";

.length {
  text-align: right;
  padding: 2rem 1rem;
  font-size: 1.8rem;
}

.companies {
  @include spacing-children("vertical", 1rem);

  @include sm {
    display: grid;
    place-content: center;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
  }
}
</style>
