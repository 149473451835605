<template>
  <div class="tabs">
    <div
      class="tab"
      :class="{ 'tab--active': isActive }"
      @click="onActiveClick"
    >
      Entreprises actives
    </div>
    <div
      class="tab"
      :class="{ 'tab--active': isOther }"
      @click="onOtherClick"
    >
      Autres entreprises
    </div>
  </div>
</template>

<script>
import enterprisesConstants from '@/constants/enterprises.constants';

export default {
  name: 'Tabs',
  props: {
    selectedTab: String,
  },
  created() {
    this.ACTIVE = 'active';
    this.OTHER = 'other';
  },
  computed: {
    isActive() {
      return this.selectedTab === enterprisesConstants.ACTIVE;
    },
    isOther() {
      return this.selectedTab === enterprisesConstants.OTHER;
    },
  },
  methods: {
    onActiveClick() {
      this.$emit('on-status-change', enterprisesConstants.ACTIVE);
    },
    onOtherClick() {
      this.$emit('on-status-change', enterprisesConstants.OTHER);
    },
  },
};
</script>

<style lang="scss" scoed>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tab {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;

  @include sm {
    font-size: 2rem;
    padding: 0.5rem 2rem;
  }

  &:hover {
    border-bottom-color: $dark-green;
  }

  &--active {
    border-bottom-color: $success;
  }
}
</style>
