<template>
  <Layout :loading="isLoading">
    <template v-if="companies.length">
      <Tabs
        :selectedTab="selectedStatus"
        @on-status-change="onStatusChange"
      />
      <Search
        class="mt-4"
        @on-change="onKeywordChange"
      />
      <EnterpriseList
        class="mt-2"
        :companies="filteredCompanies"
        :pageViews="pageViewsObject"
      />
    </template>
    <template v-else>
      Pas d&apos;entreprises
    </template>
  </Layout>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';

import lowcoApi from '@/api/lowco-api';
import enterprisesConstants from '@/constants/enterprises.constants';

import Layout from '@/components/common/Layout.vue';
import EnterpriseList from '@/components/enterprises/Enterprises.vue';
import Search from '@/components/enterprises/Search.vue';
import Tabs from '@/components/enterprises/Tabs.vue';

export default defineComponent({
  name: 'Enterprises',
  components: {
    Layout,
    EnterpriseList,
    Search,
    Tabs,
  },
  setup() {
    const isLoading = ref(false);
    const companies = ref([]);
    const pageViews = ref([]);
    const searchKeyword = ref('');
    const selectedStatus = ref(enterprisesConstants.ACTIVE);

    const filteredCompanies = computed(() => companies.value
      .filter(
        (c) => c.name.toLowerCase().includes(searchKeyword.value.toLowerCase()),
      ));
    const isActiveCompany = computed(() => selectedStatus.value === enterprisesConstants.ACTIVE);
    const pageViewsObject = computed(() => (pageViews.value.length
      ? pageViews.value.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.viewCount }), {})
      : []));

    const loadCompanies = async () => {
      try {
        isLoading.value = true;

        const result = await lowcoApi.getEnterprises(isActiveCompany.value);
        companies.value = result;
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.value = false;
      }
    };

    const loadPageViews = async () => {
      try {
        const result = await lowcoApi.getCompaniesPageViews();

        if (!result) {
          pageViews.value = [];
          return;
        }

        pageViews.value = result;
      } catch (err) {
        pageViews.value = [];
      }
    };

    const onKeywordChange = (value) => {
      searchKeyword.value = value;
    };

    const onStatusChange = (value) => {
      selectedStatus.value = value;
    };

    watch(selectedStatus, () => {
      loadCompanies();
    });

    onMounted(() => {
      loadCompanies();
      loadPageViews();
    });

    return {
      isLoading,
      companies,
      pageViewsObject,
      filteredCompanies,
      selectedStatus,
      onStatusChange,
      onKeywordChange,
    };
  },
});
</script>
